* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 1rem;
  font-family: system-ui, sans-serif;
  color: black;
  background-color: white;
}

h1 {
  font-weight: 800;
  font-size: 1.5rem;
  color: red;
}


.input1{

height: 40px;

border: 3px solid red;
max-width: 400px;
margin-top: 10px;
display: flex;
justify-content: center;

}

.Baap{
border: 4px solid rgb(226, 82, 15);
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 500px;

}




@media (min-width: 360px){

    


     input{
      width: 280px;




     }


     .Baap{
       height: 100vh;



     }



}



















































